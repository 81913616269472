<template>
	<div class="propeller-finder-button">
		<a
			href="/propellers"
			class="btn-link"
		>
			<img
				src="/skin/svg/PROPS-FILLED.svg"
				alt="Propeller"
			/>
			<span>Shop Propellers</span>
			<i class="fa fa-arrow-right"></i>
		</a>
	</div>
</template>

<script>
export default {
	name: 'PropellerFinderButton',
	props: {
		site: { type: String, default: 'bn' },
		stuck: { type: Boolean, default: false },
	},
};
</script>

<style lang="scss" scoped>
.propeller-finder-button {
	height: 100%;
	width: 100%;
	img {
		width: 2em;
	}
	a {
		align-items: center;
		color: white;
		display: flex;
		gap: 1rem;
		font-size: 1em;
		font-weight: 700;
		height: 100%;
		line-height: 1;
		justify-content: center;
	}
}
</style>
