<template>
	<div>
		<!-- MODAL -->
		<transition
			name="modal"
			mode="out-in"
		>
			<div
				v-if="open"
				class="garage-popup"
				aria-labelledby="garageModalHeading"
			>
				<div
					class="menu-shade"
					:class="{ open: open }"
					aria-hidden="true"
					@click="close"
				></div>
				<div class="popup-body">
					<!-- TOP -->
					<div class="popup-top">
						<h2
							class="hidden-xs"
							id="garageModalHeading"
						>
							<i
								class="fa-sharp fa-solid fa-wrench"
								aria-hidden="true"
							></i>
							My Garage
						</h2>
						<CloseButton
							:has-history="panelHistory.length > 0"
							@close="close"
							@go-back="goBack"
						/>
					</div>

					<!-- CONTENT -->
					<div class="popup-content">
						<GarageMenuTabs
							:site="site"
							:solrToken="machineToken"
							:axiosBaseUrl="axiosBaseUrl"
							:machineApiUrl="machineApiUrl"
							:isAuthenticated="isAuthenticated"
							:clearing="clearing"
							:enable-automotive="enableAutomotive"
							:make-model-data="makeModelData"
							:license-plate-lookup-data="licensePlateLookupData"
							:vin-lookup-data="vinLookupData"
							@selectedFitment="selectedFitment"
							@searchLicensePlate="searchLicensePlate"
							@searchVin="searchVin"
							@captureLogin="captureLogin"
							@reset:error-message="resetErrorMessages"
							:errorMessage.sync="errorMessage"
						/>
						<Panels class="visible-xs">
							<Panel>
								<PanelHeader>
									<template
										v-if="activePanelData.id !== 'stored-rides'"
										#user
									>
										<a
											v-if="!isAuthenticated"
											:href="`/account/login?reurl=${loginReURL}`"
											@click.prevent="captureLogin"
										>
											<i
												class="fa fa-user-circle"
												aria-hidden="true"
											></i>
											Sign in to see saved rides
										</a>
										<button
											v-else-if="hasStoredRide || localGarage.length > 0"
											class="btn-link"
											@click.prevent="selectGaragePanelById('stored-rides')"
										>
											<i
												class="fa fa-user-circle"
												aria-hidden="true"
											></i>
											See saved rides
										</button>
									</template>
									<PanelTitle :title="activePanelData.title" />
									<PanelDescription :description="activePanelData.description" />
								</PanelHeader>
								<component
									:id="activePanelData.id"
									:is="activePanelData.component"
									:enable-automotive="enableAutomotive"
									v-bind.sync="activePanelData.props"
									v-on:[activePanelData.event]="activePanelData.handler"
									@update:active-panel="selectGaragePanelById"
									@go-back="goBack"
									@set:ride="setRide"
									@remove:ride="removeRide"
									@remove:rides="removeMultipleRides"
									@clear:ride="clearRide"
									@reset:error-message="resetErrorMessages"
								></component>
							</Panel>
						</Panels>
					</div>

					<!-- GARAGE -->
					<div
						v-if="hasStoredRide || localGarage.length > 0"
						class="garage hidden-xs"
					>
						<div class="current">
							<h4>Currently Shopping For:</h4>
							<StoredRideButton
								v-if="hasStoredRide"
								:class="{
									selected:
										isRideSelected(storedRide) &&
										(!isRideLoading(storedRide) || !isRideBeingCleared(storedRide)),
									loading: isRideLoading(storedRide) || isRideBeingCleared(storedRide),
								}"
								@set:ride="shopForCurrent('Stored Ride Button')"
							>
								<h3 class="model">
									{{ storedRideName }}
									<i
										v-if="
											isRideSelected(storedRide) &&
											!isRideLoading(storedRide) &&
											!isRideBeingCleared(storedRide)
										"
										class="fa fa-circle-check selected"
										aria-hidden="true"
									></i>
								</h3>
								<template #engine v-if="storedRideEngine !== null">
									<p class="submodel">{{ storedRideEngine }}</p>
								</template>
								<template #remove>
									<i
										:class="[
											'fa',
											{
												'fa-spinner fa-spin':
													isRideLoading(storedRide) || isRideBeingCleared(storedRide),
											},
										]"
									></i>
								</template>
							</StoredRideButton>
							<StoredRideButton
								v-show="
									localGarageFiltered.length > 0 &&
									!hasStoredRide &&
									!isRideLoading(storedRide) &&
									!isRideBeingCleared(storedRide)
								"
								class="template loading"
								:disabled="true"
							>
								<h3>
									<em>{{ emptyStoredRideText }}</em>
								</h3>
							</StoredRideButton>

							<div
								v-show="hasStoredRide"
								class="options"
							>
								<button
									class="btn-link btn-xs"
									@click="shopForCurrent('Shop For Current Button')"
								>
									<i class="fa fa-fw fa-wrench"></i> Shop OE Parts
								</button>
								<button
									class="btn-link btn-xs"
									@click="clearRide"
								>
									<i class="fa fa-fw fa-times-circle"></i> Shop Without
									{{ capWords(vehicleType) }}
								</button>
							</div>
						</div>

						<SavedRides
							:get-ride-name="getRideName"
							:get-ride-submodel="getRideSubmodel"
							:site="site"
							:local-garage.sync="localGarage"
							:local-garage-filtered.sync="localGarageFiltered"
							:is-ride-being-cleared="isRideBeingCleared"
							:is-ride-loading="isRideLoading"
							:clearing="clearing"
							:clear-list="clearList"
							:get-garage-id="getGarageId"
							@set:ride="setRide"
							@remove:ride="removeRide"
							@remove:rides="removeMultipleRides"
							@reset:clearing="clearing = false"
						></SavedRides>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import axios from 'axios';
import garage from '@/mixins/garage.js';
import strings from '@/mixins/strings.js';
import Cookies from 'js-cookie';
import Tracking from '@/mixins/event-tracking';
import garageMenu from '@/mixins/garageMenu';

export default {
	name: 'garage-menu',
	mixins: [garage, garageMenu, strings, Tracking],
	components: {
		GarageMenuTabs: () => import('@/components/garage/GarageMenuTabs.vue'),
		Panels: () => import('@/components/garage/panels/Index.vue'),
		Panel: () => import('@/components/garage/panels/panel/Index.vue'),
		PanelHeader: () => import('@/components/garage/panels/panel/header/Index.vue'),
		PanelTitle: () => import('@/components/garage/panels/panel/header/Title.vue'),
		PanelDescription: () => import('@/components/garage/panels/panel/header/Description.vue'),
		PanelBody: () => import('@/components/garage/panels/panel/body/Index.vue'),
		FitmentBar: () => import('@/components/fitment-bar/FitmentBar.vue'),
		LicensePlateLookup: () => import('@/components/fitment-bar/LicensePlateLookup.vue'),
		VinLookup: () => import('@/components/fitment-bar/VinLookup.vue'),
		NewRide: () => import('@/components/garage/panels/NewRide.vue'),
		StoredRides: () => import('@/components/garage/panels/StoredRides.vue'),
		CloseButton: () => import('@/components/garage/CloseButton.vue'),
		StoredRideButton: () => import('@/components/garage/StoredRideButton.vue'),
		SavedRides: () => import('@/components/garage/SavedRides.vue'),
	},
	props: {
		open: {
			type: Boolean,
			default: false,
		},
		site: {
			type: String,
			default: 'pz',
		},
		isAuthenticated: {
			type: Boolean,
			default: false,
		},
		redirect: {
			type: Boolean,
		},
		machineApiUrl: {
			type: String,
		},
		machineToken: {
			type: String,
		},
		axiosBaseUrl: {
			type: String,
		},
		solrToken: {
			type: String,
		},
		autoSearchToken: {
			type: String,
		},
		enableAutomotive: { type: Boolean },
	},
	data() {
		return {
			clearing: false,
			clearList: [],
			updating: false,
			activePanel: null,
			panelHistory: [],
		};
	},
	provide() {
		return {
			site: this.site,
		};
	},
	created() {
		this.checkForStoredRide();

		if (this.hasStoredRide) {
			this.getStoredRide();
		}

		if (this.isAuthenticated) {
			this.getBackendGarage();
		} else {
			this.getLocalGarage();
		}
	},
	watch: {
		localGarage: {
			handler(newValue, oldValue) {
				if (newValue.length > 0) {
					this.selectGaragePanelById('stored-rides');
				} else {
					this.selectGaragePanelById('new-ride');
				}
			},
			deep: true,
			immediate: true,
		},
	},
	computed: {
		vehicleType() {
			if (this.site === 'pz') return 'ride';
			if (this.site === 'bn') return 'engine';
		},
		createStoredRidePanel() {
			return {
				id: 'stored-rides',
				title: 'My Garage',
				description: 'To get an exact fit, choose a previously selected ride below. Or add a new ride:',
				props: {
					loading: this.updating,
					clearing: this.clearing,
					clearList: this.clearList,
					site: this.site,
					getRideName: this.getRideName,
					localGarage: this.localGarage,
					localGarageFiltered: this.localGarageFiltered,
					getGarageId: this.getGarageId,
					isRideLoading: this.isRideLoading,
					isRideBeingCleared: this.isRideBeingCleared,
					isRideSelected: this.isRideSelected,
					getRideName: this.getRideName,
					getRideSubmodel: this.getRideSubmodel,
				},
				event: '',
				handler: '',
				component: 'StoredRides',
			};
		},
		createNewRidePanel() {
			const buttons = [
				{
					id: 'make-model',
					title: 'Make / Model',
					description: 'Search by Make, Year, Type & Model',
					icon: 'fa fa-search',
				},
			];

			if (this.enableAutomotive) {
				buttons.push(
					{
						id: 'license-plate',
						title: 'License Plate',
						description: 'Search by U.S. State License Plate',
						icon: 'fa-sharp fa-solid fa-input-text',
					},
					{
						id: 'vin-lookup',
						title: 'VIN Lookup',
						description: 'Search by Vehicle Identification Number',
						icon: 'fa-sharp fa-solid fa-rectangle-barcode',
					}
				);
			}

			return {
				id: 'new-ride',
				title: 'My Garage',
				description: 'Select an option below to get an exact fit for your ride:',
				props: {
					buttons: [...buttons],
				},
				event: '',
				handler: '',
				component: 'NewRide',
			};
		},
		panels() {
			const panels = [];

			if ((this.hasStoredRide || this.localGarage.length > 0) && this.activePanel === 'stored-rides') {
				panels.push(this.createStoredRidePanel);
			} else {
				panels.push(this.createNewRidePanel);
			}
			panels.push(this.makeModelData);

			if (this.enableAutomotive) {
				panels.push(this.licensePlateLookupData);
				panels.push(this.vinLookupData);
			}

			return panels;
		},
		activePanelData() {
			return this.panels.find((entry) => entry.id == this.activePanel);
		},
		loginReURL() {
			return btoa(location.href);
		},
		emptyStoredRideText() {
			if (this.localGarageFiltered.length >= 1) {
				return 'Choose a previously saved ride';
			}
			return 'Choose a new ride';
		},
	},
	methods: {
		captureLogin() {
			this.trackEvent('garageMenuLogin', {
				eventCategory: 'Garage Menu',
				eventLabel: 'Garage Menu Login',
			});
			window.location.href = `/account/login?reurl=${this.loginReURL}`;
		},
		/**
		 * This function closes the modal & removes classes from the HTML element.
		 */
		close() {
			if (this.open) {
				this.trackEvent('garageMenuClose', {
					eventCategory: 'Garage Menu',
					eventLabel: 'Garage Menu Close',
				});
				this.resetPanels();
				this.$emit('toggle-garage');
				document.documentElement.classList.remove('menuOpen');
			}
		},
		resetPanels() {
			if (this.hasStoredRide || this.localGarage.length > 0) {
				this.selectGaragePanelById('stored-rides');
			} else {
				this.selectGaragePanelById('new-ride');
			}
			this.resetErrorMessages();
			this.panelHistory = [];
		},
		resetErrorMessages() {
			this.errorMessage = '';
		},
		goBack() {
			this.trackEvent('garageMenuGoBack', {
				eventCategory: 'Garage Menu',
				eventLabel: 'Garage Menu Go Back (Mobile)',
			});
			if (this.panelHistory.length > 0) {
				this.activePanel = this.panelHistory.pop();
			} else {
				this.close();
			}
			this.resetErrorMessages();
		},
		/**
		 * This function sets the fitment the user as selected,
		 */
		selectedFitment() {
			this.getStoredRide();
			this.$emit('new-ride');

			this.$nextTick(() => {
				this.getStoredRide();
				this.checkForStoredRide();
				this.getLocalGarage();
				this.checkRedirect();
			});

			this.$emit('toggle-garage');
		},
		setRide(ride) {
			this.trackEvent('garageMenuSetRide', {
				eventCategory: 'Garage Menu',
				eventLabel: 'Garage Menu Set Ride',
				rideId: this.garageFitmentId,
				rideName: this.storedRideName,
			});

			this.loading = true;

			this.setStoredRide(ride);

			this.updateSelected(ride);

			this.$emit('new-ride');

			// this.$emit("toggle-garage");
		},
		async clearRide() {
			this.clearing = true;
			this.trackEvent('garageMenuClearRide', {
				eventCategory: 'Garage Menu',
				eventLabel: 'Garage Menu Clear Ride',
				rideId: this.garageFitmentId,
				rideName: this.storedRideName,
			});
			await axios
				.get('/garage/clear')
				.then(() => {
					this.removeFitmentParam();
					this.clearStoredRide();
					window.location.reload();
				})
				.catch((error) => {
					Sentry.withScope((scope) => {
						scope.setTag('component', 'Garage Menu');
						scope.setTag('action', 'Clearing Ride');
						scope.setExtras({
							ride: {
								id: this.garageFitmentId,
								name: this.storedRideName,
							},
						});
						Sentry.captureException(error);
					});
					console.error('Error clearing ride:', error);
				});

			this.$emit('new-ride');
			this.close();

			this.$emit('toggle-garage');
		},
		async removeRide(id) {
			this.clearList.push(id);
			this.clearing = true;
			if (this.isAuthenticated) {
				try {
					const url = new URL(`/account/garage/delete/${id}`, location);
					await this.deleteBackendRide(url);
				} catch (error) {
					console.error('There was an error with the delete garage request: ', error.message);
					return;
				}
			}
			await this.removeLocalRide(id);
		},
		async removeMultipleRides(ids) {
			this.clearList = ids;
			this.clearing = true;
			if (this.isAuthenticated) {
				try {
					const url = new URL('/account/garage/deleteMulti', location);
					const body = {
						ids: ids.map((id) =>
							this.getGarageId(this.localGarage.find((ride) => this.getGarageId(ride) == id))
						),
					};
					await this.deleteBackendRide(url, body);
				} catch (error) {
					this.clearing = false;
					console.error('There was an error with the delete garage request: ', error.message);
					return;
				}
			}

			await this.removeLocalRides(ids);
		},
		async deleteBackendRide(url, body = null) {
			const options = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: body ? JSON.stringify(body) : null,
			};
			const request = new Request(url, options);
			const response = await fetch(request);
			if (!response.ok) {
				Sentry.withScope(function (scope) {
					scope.setTag('component', 'Garage Menu');
					scope.setTag('action', 'Deleting Ride');
					scope.setExtras({
						url,
						body,
					});
					Sentry.setFingerprint(['{{ default }}', String(response.status)]);
					Sentry.captureException(response);
				});
				throw new Error('Sorry, we could not delete the ride from the garage.');
			}

			this.removeFitmentParam();
		},
		async removeLocalRide(id) {
			if (this.hasStoredRide && this.getGarageId(this.storedRide) === id) {
				await this.clearRide();
			}

			const updatedGarage = this.localGarage.reduce((acc, ride) => {
				if (this.getGarageId(ride) !== id) {
					acc.push(ride);
				}
				return acc;
			}, []);

			await this.updateLocalGarage(updatedGarage)
				.then(async () => {
					if (this.isAuthenticated) {
						await this.getBackendGarage();
					} else {
						this.getLocalGarage();
					}
				})
				.then(() => {
					this.clearing = false;
				});
		},
		async removeLocalRides(ids) {
			if (this.hasStoredRide && ids.includes(this.getGarageId(this.storedRide))) {
				await this.clearRide();
			}

			const updatedGarage = this.localGarage.reduce((acc, ride) => {
				if (!ids.includes(this.getGarageId(ride))) {
					acc.push(ride);
				}
				return acc;
			}, []);

			await this.updateLocalGarage(updatedGarage)
				.then(async () => {
					if (this.isAuthenticated) {
						await this.getBackendGarage();
					} else {
						this.getLocalGarage();
					}
				})
				.then(() => {
					this.clearing = false;
				})
				.then(() => {
					this.clearList = [];
				});
		},
		buildRideName(ride) {
			const { name, year, make, model } = ride;
			if (name && name !== null) {
				return name;
			}

			return `${year} ${make} ${model}`;
		},
		shopForCurrent(eventSource) {
			if (this.hasStoredRide) {
				if (this.garageFitmentId) {
					this.trackEvent('garageMenuShopForCurrent', {
						eventCategory: 'Garage Menu',
						eventLabel: 'Garage Menu Shop For Current',
						rideId: this.garageFitmentId,
						rideName: this.storedRideName,
						eventSource,
					});
					const url = new URL(
						`/catalog/v1/${this.garageFitmentId}/${this.storedRideName
							.replace(/[-]/g, '')
							.replace(/\s/g, '-')
							.replaceAll('/', '_')}`,
						location
					);
					url.searchParams.set('use_fitment', 1);
					url.searchParams.set('ui', 'typeahead');
					Cookies.set('use_fitment', 1);
					window.location = url.href;
				}
				return;
			}

			// alert('Please choose a ride to shop for ride!');
		},
		searchFromQuery() {
			let search = '/search?q=+fitment:' + this.garageFitmentId;

			window.location = search;
		},
		selectGaragePanelById(id) {
			this.trackEvent('garageMenuSelectPanel', {
				eventCategory: 'Garage Menu',
				eventLabel: 'Garage Menu Select Panel',
				panelId: id,
			});
			if (this.activePanel !== null && this.activePanel !== id) {
				this.panelHistory.push(this.activePanel);
			}
			this.activePanel = id;
		},
	},
};
</script>

<style lang="scss" scoped>
.manage-garage-button {
	font-weight: 700;
	margin-top: 1em;
	text-transform: uppercase;
}
.garage-popup {
	align-items: center;
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	min-height: 100vh;
	overflow-y: scroll;
	position: fixed;
	right: 0;
	transition: all 500ms ease-in-out;
	top: 0;
	z-index: 10;

	.menu-shade {
		&.open {
			background-color: rgba(0, 0, 0, 0.45);
			background-color: color(display-p3 0 0 0 / 0.45);
			backdrop-filter: blur(calc(3 / var(--default-font-size, 14) * 1rem));
			overflow-y: scroll;
			pointer-events: all;
			position: fixed;
		}
	}

	.popup-body {
		background-color: white;
		background-color: color(display-p3 1 1 1);
		box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
		display: flex;
		flex-direction: column;
		gap: calc(36 / var(--default-font-size, 14) * 1rem);
		position: absolute;
		transition: all 500ms ease-in-out;
		top: 5%;
		width: max(768px, 60vw);
		z-index: 200;

		.popup-top {
			align-items: center;
			align-self: stretch;
			border-bottom: 2px solid #eee;
			border-bottom: 2px solid color(display-p3 0.9333 0.9333 0.9333);
			display: flex;
			justify-content: space-between;
			margin: 0 calc(32 / var(--default-font-size, 14) * 1rem);
			padding: calc(16 / var(--default-font-size, 14) * 1rem) 0;

			h2 {
				align-items: center;
				color: #222;
				color: color(display-p3 0.1333 0.1333 0.1333);
				display: flex;
				font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
					Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
				font-size: calc(20 / var(--default-font-size, 14) * 1rem);
				font-style: normal;
				font-weight: 700;
				gap: 1rem;
				justify-content: flex-start;
				line-height: normal;
				i {
					font-size: calc(22 / var(--default-font-size, 14) * 1rem);
					font-style: normal;
					line-height: normal;
				}
			}
		}

		.popup-content {
			display: flex;
			flex-direction: column;
		}

		.garage {
			align-items: flex-start;
			display: flex;
			gap: calc(32 / var(--default-font-size, 14) * 1rem);
			justify-content: center;
			padding: 0 calc(32 / var(--default-font-size, 14) * 1rem);
			position: relative;
			transition: all 500ms ease-in-out;

			& > :not([hidden]),
			& ~ :not([hidden]) {
				align-items: flex-start;
				align-self: stretch;
				display: flex;
				flex: 0 1 50%;
				flex-direction: column;
				gap: calc(24 / var(--default-font-size, 14) * 1rem);
				padding: 0 0 calc(32 / var(--default-font-size, 14) * 1rem);
			}

			h4 {
				color: #000;
				color: color(display-p3 0 0 0);
				font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
					Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
				font-size: calc(20 / var(--default-font-size, 14) * 1rem);
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: none;
				margin: 0;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.garage-popup {
		top: unset;
		.popup-body {
			align-items: flex-start;
			align-self: stretch;
			bottom: 0;
			display: flex;
			flex-direction: column;
			gap: calc(10 / var(--default-font-size, 14) * 1rem);
			justify-content: flex-end;
			padding: calc(16 / var(--default-font-size, 14) * 1rem);
			top: unset;
			width: max(328px, 100vw);
			.popup-top {
				border: 0;
				align-items: center;
				align-self: stretch;
				display: flex;
				gap: calc(10 / var(--default-font-size, 14) * 1rem);
				margin: 0;
				padding: calc(8 / var(--default-font-size, 14) * 1rem) calc(16 / var(--default-font-size, 14) * 1rem);
			}
			.popup-content {
				align-items: center;
				align-self: stretch;
				justify-content: center;
				margin: 0;
				.fitment-container {
					align-items: flex-start;
					align-self: stretch;
					display: flex;
					flex-direction: column;
					gap: calc(24 / var(--default-font-size, 14) * 1rem);
					width: 100%;
				}
			}
			.garage {
				flex-direction: column;
				.current {
					flex-basis: unset;
				}

				.garage-list {
					&:only-child {
						margin-left: 0 !important;
					}
				}

				.options {
					flex-basis: unset;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;

					button {
						width: 100%;
						border: none !important;
						font-size: 1.4rem;
					}
				}
			}
		}
	}
}
</style>
