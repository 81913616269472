<template>
  <div class="autocomplete">
    <div
      class="autocomplete-container"
      :class="site"
      @mouseenter="$emit('mouseenter')"
      @mouseleave="$emit('mouseleave')"
    >
      <div class="results-container">
        <div
          class="result-list-container"
          v-if="results.length > 0 && !queryLoading && query.length > 2"
        >
          <div
            v-for="(result, grpIndex) in results"
            :key="result.groupValue"
            class="results-list"
          >
            <div class="group-header">{{ result.groupValue }}</div>
            <a
              class="result styled-item"
              v-for="(doc, index) in result.doclist.docs.slice(0, 5)"
              :key="doc.id"
              :class="{
                active: currentSelection === index && currentGroup === grpIndex,
              }"
              @click="submitQuery(doc, false, true)"
              @mouseenter="mouseSelect(grpIndex, index)"
            >
              <span v-html="styledResult(doc, result)"></span>
              <i
                v-if="doc.type !== 'Suggestion'"
                class="fa fa-fw fa-chevron-right text-muted"
              ></i>
            </a>
          </div>
        </div>
						<!-- Product Results-->
<!--        <div-->
<!--          class="expanded"-->
<!--          v-if="hasExpandedResults"-->
<!--          style="max-width: 320px"-->
<!--        >-->
<!--          <div-->
<!--            v-for="(doc, index) in results[currentGroup].doclist.docs"-->
<!--            :key="doc.id"-->
<!--          >-->
<!--            <product-result-->
<!--              :doc-key="fusionData(doc.product_doc_key)"-->
<!--              v-if="index === currentSelection"-->
<!--              :auth="auth"-->
<!--							:doc-product-name="doc.product_name"-->
<!--							:doc-product-id="doc.id"-->
<!--              :cda="cda"-->
<!--              :domain="domain"-->
<!--              :site="site"-->
<!--              :addMin="addMin"-->
<!--              :addMax="addMax"-->
<!--              :holiday="holiday"-->
<!--              :dc="dc"-->
<!--            ></product-result>-->
<!--          </div>-->
<!--        </div>-->

        <!-- POPULAR SEARCHES -->
<!--        <div-->
<!--          class="popular-searches result-list-container"-->
<!--          v-if="-->
<!--            !queryLoading &&-->
<!--            query.length <= 2 &&-->
<!--            this.hasStoredRide &&-->
<!--            !isMobile-->
<!--          "-->
<!--        >-->
<!--          <div class="results-list" v-for="p in popularItems" :key="p.title">-->
<!--            <div class="group-header">{{ p.title }}</div>-->
<!--            <a-->
<!--              class="result styled-item"-->
<!--              v-for="i in p.items"-->
<!--              :href="i.url"-->
<!--              :key="i.label"-->
<!--            >-->
<!--              {{ i.label }}-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->

        <!-- POPULAR BRANDS -->
<!--        <div class="result-list-container" v-else-if="showPopularBrands">-->
<!--          <div class="results-list">-->
<!--            <div class="group-header">Popular Brands</div>-->
<!--            <a-->
<!--              class="result styled-item"-->
<!--              v-for="p in popularBrandsDecoded"-->
<!--              :key="p.label"-->
<!--              :href="p.url"-->
<!--            >-->
<!--              {{ p.label }}-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->

        <!-- SHOP BY SIDEBAR -->
<!--        <div-->
<!--          class="expanded"-->
<!--          style="width: 100%; max-width: 320px"-->
<!--          v-if="!queryLoading && query.length <= 2"-->
<!--        >-->
<!--          <div class="shop-by" v-if="hasStoredRide">-->
<!--            <button-->
<!--              class="btn btn-default btn-sm"-->
<!--              v-if="hasCatalog"-->
<!--              @click="goToComponent"-->
<!--            >-->
<!--              Shop Component Diagrams-->
<!--            </button>-->
<!--            &lt;!&ndash; <button class="btn btn-primary btn-sm" @click="shopAll">-->
<!--              Shop By Category-->
<!--            </button> &ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->

        <div class="info text-muted" v-if="queryLoading">
          <i class="fa fa-fw fa-spinner fa-spin fa-2x"></i>
        </div>

        <div
          class="info text-muted"
          v-if="results.length === 0 && query.length > 2 && !queryLoading"
        >
          <a @click="submitQuery()" class="search-for">
            <em>
              Search for
              <strong>{{ query }}</strong>
              <span v-if="selectedFitmentText.length > 0"
                >&nbsp;for your {{ selectedFitmentText }}</span
              >
            </em>
          </a>
        </div>
      </div>

      <!-- BELOW RESULTS -->
      <div class="below-results">
        <a class="multi" href="/search-list"
          >Use Multi-Part Search <i class="fa fa-fw fa-chevron-right"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/event-bus.js";
import debounce from "lodash/debounce";
import ProductResult from "./ProductResult.vue";
import garage from "@/mixins/garage.js";
import data from '@/mixins/data.js';
import Cookies from "js-cookie";
import Tracking from '@/mixins/event-tracking';

export default {
  name: "autocomplete",
  mixins: [garage, data, Tracking],
  components: { ProductResult },
  props: {
    query: { type: String },
    results: { type: Array },
    styledResults: { type: [Object, Array] },
    selectedFitment: { type: String, default: "" },
    selectedFitmentText: { type: String, default: "" },
    queryLoading: { type: Boolean },
    auth: { type: Number },
    cda: { type: Number },
    domain: { type: String },
    addMin: { type: String },
    addMax: { type: String },
    holiday: { type: Boolean },
    site: { type: String },
    searchItems: { type: Array },
    isMobile: { type: Boolean },
    popularBrands: { type: String },
    dc: {},
    productPipeline: { type: String },
    typeaheadPipeline: { type: String },
    queryBearerToken: { type: String },
    startTime: { type: Number, default: 0 },
  },

  data() {
    return {
      loading: true,
      currentGroup: 0,
      currentSelection: -1,
      hoverSelection: false,
      canHover: true,
      popularSearchesPz: [
        {
          title: "Popular Searches",
          items: [
            { label: "Brake Kits", url: "/search?q=brake+kits" },
            { label: "Top End Kits", url: "/search?q=top+end+kits" },
            { label: "Oil Filter", url: "/search?q=oil+filters" },
            { label: "Air Filters", url: "/search?q=air+filters" },
            { label: "Wheels & Tires", url: "/search?q=wheels+tires" },
          ],
        },
      ],
      popularSearchesBn: [
        {
          title: "Popular Searches",
          items: [
            { label: "Control Cables", url: "/search?q=control+cables" },
            { label: "Impellers", url: "/search?q=impellers" },
            { label: "Propellers", url: "/search?q=propellers" },
          ],
        },
      ],
    };
  },

  computed: {
    popularBrandsDecoded() {
      return this.parseBase64(this.popularBrands);
    },
    hasExpandedResults() {
      return (
        this.currentSelection > -1 &&
        this.results.length > 0 &&
        this.results[this.currentGroup].doclist &&
        this.results[this.currentGroup].doclist.docs &&
        this.results[this.currentGroup].doclist.docs[this.currentSelection]
          .product_doc_key
      );
    },
    hasCatalog() {
      if (this.hasStoredRide) {
        const ride = this.storedRide;

        if (ride.catalog && ride.catalog.type_id === "196") return true;
      }

      return false;
    },
    popularItems() {
      if (this.searchItems.length > 0) return this.searchItems;

      return this.popularSearches;
    },
    showPopularBrands() {
      if (this.isMobile) {
        return false;
      } else {
        return !this.hasStoredRide && !this.hasExpandedResults;
      }
    },
    popularSearches() {
      return this.site === "pz"
        ? this.popularSearchesPz
        : this.popularSearchesBn;
    },
  },

  watch: {
    results() {
      this.currentGroup = 0;
      this.currentSelection = -1;
    },
  },

  methods: {
    changeSelection(val) {
      if (this.results.length === 0) return;

      this.hoverSelection = false;
      this.canHover = false;

      this.currentSelection += val;

      if (this.currentSelection < -1) {
        if (this.currentGroup > -1) {
          this.currentGroup--;
        }

        if (this.currentGroup <= -1) {
          this.currentGroup = this.results.length - 1;
        }

        this.currentSelection =
          this.results[this.currentGroup].doclist.docs.slice(0, 5).length - 1;
      }

      if (
        this.currentSelection >
        this.results[this.currentGroup].doclist.docs.slice(0, 5).length - 1
      ) {
        this.currentGroup++;
        this.currentSelection = 0;

        if (this.currentGroup > this.results.slice(0, 5).length - 1) {
          this.currentGroup = 0;
          this.currentSelection = -1;
        }
      }

      this.emitSelection();
    },
    emitSelection() {
      EventBus.$emit("UpdateSelection", {
        data: this.results[this.currentGroup].doclist.docs[
          this.currentSelection
        ],
        index: this.currentSelection,
      });
    },
    onHover(g, s) {
      if (!this.hoverSelection) this.canHover = true;

      if (this.canHover) {
        this.currentGroup = g;
        this.currentSelection = s;
        this.hoverSelection = true;
      }
    },
    searchQuery(name) {
      let q = this.domain;
      
      return (
        q +
        ("/search/typeahead?q=" +
          name.replace(/ /gm, "+"))
      );
    },
    styledResult(doc, result) {
      let styled = "";

      if (this.styledResults[doc.id].name) {
        styled += this.fusionData(this.styledResults[doc.id].name[0]);
      } else if (this.styledResults[doc.id].name_contains) {
        styled += this.fusionData(this.styledResults[doc.id].name_contains[0]);
      }

      if (result.groupValue === "Product") {
        if(styled==""){
          let matched_product = result.doclist.docs.find(el => el.product_name == doc.product_name);
          if(matched_product){
            styled=Array.isArray(matched_product.display_name) ? matched_product.display_name[0] : matched_product.display_name;
          }
        }
       
        styled += " &ndash; " + doc.product_name;
        return styled.replace(/_/g, "-");
      }

      if(styled==""){
        styled += doc.product_name;
      }

      return styled.replace(/_/g, " ");
    },
    submitQuery(doc = "", toggled = false, clicked = false) {
      if (this.query.length === 0) return;

      if (toggled) {
        this.trackEvent('searchSubmit', {
          eventCategory: 'Search',
          eventLabel: 'Search Submit',
          searchSource: inputSource,
          searchTimeInSeconds: new Date().getSeconds() - this.startTime,
          search: this.query,
        });
        if (doc.type === "Product") {
          let term = this.query.replace(/ /gm, "+");
          if (Cookies.get('use_fitment')) {
            term = `${term}&use_fitment=1`;
          }
          window.location = "/search?q=" + encodeURIComponent(term);
          return;
        }

        let regex = /&/g;

        if (regex.test(this.fusionData(doc.href[0]))) {
          let term = this.buildToggledQuery(doc);
          if (Cookies.get('use_fitment')) {
            term = `${term}&use_fitment=1`;
          }
          window.location = encodeURI(term);
          return;
        }
      }

      if (clicked) {
        if (doc.type !== "Suggestion") {
          let type = doc.type.toLowerCase().replace(/ /g, "-");
        }
        let term = this.buildQuery(doc);
        if (doc.type !== "Product") {
          if (Cookies.get('use_fitment')) {
            term = `${term}&use_fitment=1`;
          }
        }
        this.trackEvent('searchSubmit', {
          eventCategory: 'Search',
          eventLabel: 'Search Submit',
          searchSource: 'Typeahead',
          search: this.query,
        });
        window.location = encodeURI(term);
        return;
      }

      let term;

      if (this.currentSelection > -1 && !this.hoverSelection) {
        let g = this.currentGroup;
        let r = this.currentSelection;
        let doc = this.results[g].doclist.docs[r];

        term = this.buildQuery(doc);
        if (Cookies.get('use_fitment')) {
          term = `${term}&use_fitment=1`;
        }
      } else {
        term = "/search?q=" + this.sanitizeQuery(this.query) + "&ui=typeahead";

        if (Cookies.get('use_fitment')) {
          term = `${term}&use_fitment=1`;
        }

        if (this.selectedFitment.length > 0) {
          term += this.selectedFitment;
        }
      }

      window.location = term;
    },
    popularSearch(term) {
      let search = term;

      if (this.selectedFitment !== "") search += this.selectedFitment;

      window.location = search;
    },
    goToComponent() {
		if (this.garageFitmentId) {
        	window.location = `/v1/catalog/${this.garageFitmentId}/${this.storedRideName.replace(/[-]/g, '').replace(/\s/g, '-')}`;
      	} else {
        	window.location = "/search?q=parts&ui=typeahead";
      	}
    },
    shopAll() {
      window.location = "/search?q=*:*" + this.selectedFitment;
    },
    buildQuery(doc) {
      let q = this.fusionData(doc.href);
      return this.sanitizeQuery(q);
    },
    buildToggledQuery(doc) {
      let split = this.query.split(doc.id);
      let q = split[1].replace(/ /, "").replace(/ /gm, "+").replace(/&/gm, "%26");

      return this.fusionData(doc.href[0]) + "&q=" + q;
    },
    sanitizeQuery(q) {
      return q.replace(/ /gm, "+").replace(/&/gm, "%26");
    },
    fusionData(data) {
      if (Array.isArray(data)) return data[0];

      return data;
    },
  },

  created() {
    this.mouseSelect = debounce(this.onHover, 500);
  },

  mounted() {
    this.checkForStoredRide();

    if (this.hasStoredRide) {
      this.getStoredRide();
    }

    EventBus.$on("ChangeSelection", (val) => {
      this.changeSelection(val);
    });

    EventBus.$on("AttemptSubmission", (t) => {
      this.submitQuery(t.doc, t.toggled);
    });
  },
};
</script>

<style lang="scss">
div.autocomplete {
  position: relative;

  div.autocomplete-container {
    position: absolute;
    background-color: white;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    // box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 999;

    border: 2px solid;
    top: -6px;

    border-top: none;

    &.pz {
      border-color: #c3161c;
    }

    &.bn {
      border-color: #ef6225;
    }

    &.fd {
      border-color: #33ac00;
    }

    div.results-container {
      display: flex;
      flex-direction: row;

      div.result-list-container {
        padding: 8px;
        display: flex;
        flex-direction: column;
        text-align: left !important;
        width: 100%;
        // padding: 6px;

        div.results-list {
          display: flex;
          flex-direction: column;

          padding-left: 5px;
          margin-bottom: 5px;
          border-bottom: 1px solid #dedede;

          &:last-of-type {
            border-bottom: none;
          }

          div.group-header {
            font-size: 1.5rem;
            font-weight: 700;
            color: black;
            text-transform: uppercase;
            padding: 5px 4px 2px 4px;
          }

          a.result {
            padding: 0.25em 0.5em;
            color: #666;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &:hover {
              cursor: pointer;
              background-color: #f3f3f3;
            }

            em {
              font-weight: 700;
              font-style: unset;
            }

            &.active {
              background-color: #f3f3f3;
            }
          }
        }
      }
    }

    div.expanded {
      border-left: 1px solid #dedede;
      width: 50%;

      div.shop-by {
        padding: 0.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button,
        a {
          margin-bottom: 3px;
          font-weight: 700;
          text-transform: uppercase;
          max-width: 240px;
          min-width: 200px;
        }
      }
    }

    div.info {
      text-align: center !important;
      padding: 0.5em 0;
      width: 100%;
      align-self: center;
      justify-self: center;

      a.search-for:hover {
        cursor: pointer;
      }
    }
  }
}

div.below-results {
  width: 100%;
  padding: 0 1em;
  margin-top: 0.5em;

  a.multi {
    display: block;
    text-align: center;
    width: 100%;
    border-top: 1px solid #e3e3e3;
    font-weight: 700;
    text-transform: uppercase;
    padding: 1em 0;
  }
}

@media (max-width: 768px) {
  div.autocomplete {
    div.autocomplete-container {
      flex-direction: column;
    }
  }
}
</style>
